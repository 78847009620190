import axios from '../../services/axiosInstance';
import errorHandler from '../../services/error/handler';

const get = async (storeId, frequent = false, sectioned = false) => {
  try {
    const response = await axios.get(
      `/ayuda?code=${storeId}${frequent ? '&information=true' : ''}${
        sectioned ? '&sectioned=true' : ''
      }`
    );

    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const getLocation = async () => {
  try {
    const response = await axios.get('/geocoding');
    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const message = errorHandler(err, true);

    return {
      success: false,
      error: message
    };
  }
};

const getCardPromos = async () => {
  try {
    const response = await axios.get('/ayuda?code=vaypol&promos=true');

    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};
const getHelp = async (storeId, help = false) => {
  try {
    const response = await axios.get(
      `/ayuda?code=${storeId}${help ? '&help=true' : ''}`
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

export default {
  get,
  getHelp,
  getCardPromos,
  getLocation
};
