/* eslint-disable react/no-unknown-property */
import 'styles/main.scss';
import { Montserrat } from 'next/font/google';

import { Router, useRouter } from 'next/router';
import {
  useState,
  useEffect
} from 'react'; /* eslint-disable react/jsx-props-no-spreading */
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { useStore } from '../redux/store';
import {
  ThemeProvider,
  ErrorProvider,
  EventTracking
} from '@/components/organisms';
import {
  AsistantChat,
  ChangeModalCart,
  PageSpinner
} from '@/components/molecules';
import useScrollRestoration from '@/hooks/useScrollRestoration';
import useLiveInfo from '@/hooks/useLiveInfo';
import analytics from '@/utils/vaypolAnalytics';

const montserrat = Montserrat({
  weight: ['100', '300', '400', '600', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap'
});
const MyApp = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const live = useLiveInfo(store);
  // guarda el scroll de la pagina anterior
  useScrollRestoration(router);
  useEffect(() => {
    analytics.vaypolAnalytics(pageProps.referer);
    setInterval(() => {
      analytics.run_analytics();
    }, 2000);
    Router.events.on('routeChangeStart', () => setLoading(true));
    Router.events.on('routeChangeComplete', () => setLoading(false));
    return () => {
      Router.events.off('routeChangeComplete', () => setLoading(false));
      Router.events.off('routeChangeStart', () => setLoading(true));
    };
  }, []);
  return (
    <Provider store={store}>
      <EventTracking>
        <ThemeProvider>
          <ErrorProvider>
            <style
              jsx
              global
            >{`
              * {
                font-family: ${montserrat.style.fontFamily};
              }
            `}</style>
            {loading && <PageSpinner />}
            {!router.pathname.includes('checkout') && <AsistantChat />}
            <Component
              {...pageProps}
              liveInfo={live}
            />
            <ChangeModalCart />
            {/* Container para inyectar luego la lib de toastify */}
            <div id="root-toastify" />
            <div id="portal-root" />
          </ErrorProvider>
        </ThemeProvider>
      </EventTracking>
    </Provider>
  );
};

MyApp.getInitialProps = async ({ ctx }) => {
  const referer = ctx?.req?.headers?.referer;
  return {
    pageProps: {
      referer
    }
  };
};

export default MyApp;

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};
